import { useEffect, useState } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import {
  getExercises,
  getExerciseUrl,
  getWorkoutData,
} from "../../functions/workoutFunctions";
import ReactPlayer from "react-player";
import { useTimer } from "react-timer-hook";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { getRandomColor } from "../../helper-functions/UtilFunctions";

function ViewWorkoutPage() {
  const [workoutData, setWorkoutData] = useState({});
  const [exerciseData, setExerciseData] = useState([]);
  const [gifUrl, setGifUrl] = useState("");
  const [exerciseName, setExerciseName] = useState("");
  const [showExercise, setShowExercise] = useState(true);
  const [playExercise, setPlayExercise] = useState(false);
  const [exerciseIndex, setExerciseIndex] = useState(0);
  const [setIndex, setSetIndex] = useState(0);
  const [timerColor, setTimerColor] = useState("clickable");
  const [finished, setFinished] = useState(false);

  const [showVideoMenu, setShowVideoMenu] = useState(false);
  const { id } = useParams();

  const repTime = 30;

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiretyTimestamp: new Date().setSeconds(new Date().getSeconds() + 300),
    onExpire: () => timerFinished(),
    autoStart: false,
  });

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.title = "Daly Exercise+ | Workout";
    getWorkout(id);
  }, [id]);

  const getWorkout = async (id) => {
    setLoading(true);
    const workoutData = await getWorkoutData(id);

    if (workoutData !== undefined && Object.keys(workoutData).length !== 0) {
      setWorkoutData(workoutData);

      const exercises = await getExercises(workoutData.Exercises);
      setExerciseData(exercises);
      await new Promise((r) => setTimeout(r, 200));
      startExercises(exercises[0]);
    }
  };

  const startExercises = async (exercise) => {
    setLoading(true);
    let url = await getExerciseUrl(`homework/videos/${exercise.GifUri}`);
    setGifUrl(url);
    setExerciseName(exercise.Name);
    getNewTimer(repTime, false);
    setLoading(false);
  };

  const goToNextExercise = async (exercise) => {
    setLoading(true);
    let url = await getExerciseUrl(`homework/videos/${exercise.GifUri}`);
    setGifUrl(url);
    setExerciseName(exercise.Name);
    await new Promise((r) => setTimeout(r, 200));
    getNewTimer(repTime, true);
    setLoading(false);
  };

  const exerciseClicked = async () => {
    if (isRunning) {
      pause();
    } else {
      resume();
    }
    setPlayExercise(!playExercise);
    setShowVideoMenu(true);
    await new Promise((r) => setTimeout(r, 1000));
    setShowVideoMenu(false);
  };

  const getNewColor = () => {
    const color = getRandomColor();
    setTimerColor(color);
  };

  const getNewTimer = (length, start) => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + length);
    restart(time, start);
    getNewColor();
  };

  const timerFinished = async () => {
    if (showExercise) {
      setShowExercise(false);
      await new Promise((r) => setTimeout(r, 300));
      getNewTimer(repTime, true);
    } else {
      setShowExercise(true);
      if (
        setIndex + 1 === workoutData.Sets &&
        exerciseIndex + 1 >= exerciseData.length
      ) {
        setFinished(true);
      } else if (exerciseIndex + 1 >= exerciseData.length) {
        setExerciseIndex(0);
        setSetIndex(setIndex + 1);
        await goToNextExercise(exerciseData[0]);
      } else {
        await goToNextExercise(exerciseData[exerciseIndex + 1]);
        setExerciseIndex(exerciseIndex + 1);
      }
    }
  };

  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container className="d-none d-lg-block">
          <Row className="">
            <Col md={6}>
              {finished ? (
                <div className="white-box d-flex justify-content-center  vh-70">
                  <div className="m-auto p-3">
                    <div className="strand-icon-wrapper w-100">
                      <img
                        src={`/icons/icon_congrats.png`}
                        className="congrats-icon"
                        alt="Congrats Icons"
                      />
                    </div>
                    <br />
                    <br />
                    <h1 className="congrats-text text-center">
                      Workout Finished!
                    </h1>
                  </div>
                </div>
              ) : (
                <div
                  className="equal-height d-flex justify-content-center full-screen-player p-0 player-container"
                  onClick={() => exerciseClicked()}
                >
                  {loading ? (
                    <div className="custom-loader"></div>
                  ) : showExercise ? (
                    <ReactPlayer
                      className="react-player-full player-content"
                      url={gifUrl}
                      loop={true}
                      playing={playExercise}
                      mute={true}
                    />
                  ) : (
                    <div className="m-auto player-content">
                      <h1 className="pageTitle title-margin">Break Time!</h1>
                    </div>
                  )}
                  <div class="player-overlay">
                    <div className="strand-icon-wrapper">
                      {!playExercise ? (
                        <span class="material-symbols-outlined play-icon">
                          play_arrow
                        </span>
                      ) : (
                        <span class="material-symbols-outlined pause-icon">
                          pause
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Col>
            <Col md={6}>
              <div className="white-box vh-70">
                <div className="vh-25">
                  <h1 className="pageTitle title-margin">{exerciseName}</h1>
                  <div className="hr-dark" />

                  <Stack direction="horizontal">
                    <h3 className=" m-auto">
                      Set: {setIndex + 1} / {workoutData.Sets}
                    </h3>
                    <h3 className="m-auto">
                      Exercise: {exerciseIndex + 1} / {exerciseData.length}
                    </h3>
                    <br />
                  </Stack>
                  <div className="hr-dark" />
                </div>

                <div className="timer-container max-vh-35">
                  <CircularProgressbar
                    minValue={0}
                    maxValue={repTime}
                    value={seconds}
                    text={seconds}
                    className="max-vh-35"
                    styles={buildStyles({
                      strokeLinecap: "butt",

                      pathTransitionDuration: 0.5,
                      pathColor: `var(--${timerColor})`,
                      textColor: `var(--${timerColor})`,
                      trailColor: "white",
                    })}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="d-lg-none">
          <div className="white-box mt-0">
            <div className="">
              <Stack direction="horizontal">
                <h1 className="pageTitle title-margin me-auto">
                  {exerciseName}
                </h1>
                <CircularProgressbar
                  minValue={0}
                  maxValue={repTime}
                  value={seconds}
                  text={seconds}
                  className="max-vh-15"
                  styles={buildStyles({
                    strokeLinecap: "butt",

                    pathTransitionDuration: 0.5,
                    pathColor: `var(--${timerColor})`,
                    textColor: `var(--${timerColor})`,
                    trailColor: "white",
                  })}
                />
              </Stack>

              <div className="hr-dark" />

              <Stack direction="horizontal">
                <h3 className=" m-auto">
                  Set: {setIndex + 1} / {workoutData.Sets}
                </h3>
                <h3 className="m-auto">
                  Exercise: {exerciseIndex + 1} / {exerciseData.length}
                </h3>
                <br />
              </Stack>
              {/* <div className="hr-dark" /> */}
            </div>
          </div>
          {finished ? (
            <div className="white-box d-flex justify-content-center p-0 ">
              <div className="m-auto p-3">
                <div className="strand-icon-wrapper w-100">
                  <img
                    src={`/icons/icon_congrats.png`}
                    className="congrats-icon-m"
                    alt="Congrats Icons"
                  />
                </div>
                <br />
                <br />
                <h1 className="congrats-text-m text-center">
                  Workout Finished!
                </h1>
              </div>
            </div>
          ) : (
            <div
              className={`d-flex justify-content-center full-screen-player-m p-0  ${
                showVideoMenu ? "player-container-m" : ""
              }`}
              onClick={() => exerciseClicked()}
            >
              {loading ? (
                <div className="custom-loader"></div>
              ) : showExercise ? (
                <ReactPlayer
                  className="react-player-full-m player-content-m"
                  url={gifUrl}
                  loop={true}
                  playing={playExercise}
                  mute={true}
                  height="100%"
                />
              ) : (
                <div className="m-auto player-content">
                  <h1 className="pageTitle title-margin">Break Time!</h1>
                </div>
              )}
              <div class={`player-overlay`}>
                <div className="strand-icon-wrapper">
                  {!playExercise ? (
                    <span class="material-symbols-outlined play-icon">
                      play_arrow
                    </span>
                  ) : (
                    <span class="material-symbols-outlined pause-icon">
                      pause
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </Container>
      </div>
    </Container>
  );
}

export default ViewWorkoutPage;
