import { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router";
import {
  getAllCategoriesNew,
  getAllLessons,
  getAllMovementBreakStrands,
  getAllStrandsNew,
  getAllSubcategoriesNew,
  uploadAllCategoriesNew,
  uploadAllLessonsNew,
  uploadAllSubCategoriesNew,
} from "../../firebase";
import CategoryBox from "../../components/CategoryBox";
import SearchBarLink from "../../components/SearchBarLink";
import { getEventStrandsByYear, getStrandsByYear } from "../../firebase";
import { UserContext } from "../../providers/UserProvider";
import StructuredLessonItem from "../../components/StructuredLessonItem";
import StrandCategoryBox from "../../components/new-components/StrandCategoryBox";

function UpdatedMovementBreakListPage() {
  const [strandsText, setStrandsText] = useState("");
  const [strands, setStrands] = useState([]);
  const { data, data2 } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Daly Exercise+ | Movement Break Strands";
    if ("UserName" in data) {
      switch (parseInt(data2.Year)) {
        case 0:
          setStrandsText("Junior & Senior");
          break;
        case 1:
          setStrandsText("1st & 2nd");
          break;
        case 2:
          setStrandsText("3rd & 4th");
          break;
        case 3:
          setStrandsText("5th & 6th");
          break;
        default:
          setStrandsText("ERROR");
      }
      setLoading(true);
      getAllMovementBreakStrands().then((res) => {
        if (res.length > 0) {
          setStrands(res.sort((a, b) => a.StrandNumber > b.StrandNumber));
        }
        setLoading(false);
      });
    }
  }, [data, data2]);

  const goToStrand = (guid) => {
    navigate(guid);
  };

  const goToWorkoutsType = (type) => {
    navigate("/workouts/" + type);
  };
  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">
            Movement Breaks for {strandsText}
          </h1>
          <br />
          {loading ? (
            <div className="custom-loader"></div>
          ) : (
            <div>
              <Row className="structured-lesson-list-container justify-content-center">
                {strands?.map((strand, i) => {
                  if (strand.Active) {
                    return (
                      <Col md={4} key={i}>
                        <StrandCategoryBox
                          title={strand.Title}
                          icon={strand.ImageUri}
                          color={strand.ColourName}
                          content={strand.Title}
                          click={() => goToStrand(strand.Guid)}
                        />
                      </Col>
                    );
                  }
                  return "";
                })}
              </Row>
              <Row className="structured-lesson-list-container justify-content-center">
                <Col md={4}>
                  <StrandCategoryBox
                    title="Random Workout"
                    icon="icon_random"
                    color="calmingColour"
                    content="Random Workout"
                    click={() => goToWorkoutsType("random")}
                  ></StrandCategoryBox>
                </Col>
                <Col md={4}>
                  <StrandCategoryBox
                    title="Custom Workout"
                    icon="icon_create"
                    color="yellow"
                    content="Custom Workout"
                    click={() => goToWorkoutsType("custom")}
                  ></StrandCategoryBox>
                </Col>
                <Col md={4}>
                  <StrandCategoryBox
                    title="Saved Workouts"
                    icon="icon_classroom_workout"
                    color="textPrimary"
                    content="My Workouts"
                    click={() => goToWorkoutsType("saved")}
                  ></StrandCategoryBox>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </div>
    </Container>
  );
}

export default UpdatedMovementBreakListPage;
