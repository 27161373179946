import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";

import { httpsCallable } from "firebase/functions";

import { firestoreDb, functions } from "../firebase";
import { getEmptyAccount } from "../models/account";

export async function getAnalyticalData() {
  try {
    const callable = httpsCallable(functions, "getSchoolAnalyticalData");
    let response = await callable();
    return response.data;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function downloadAnalyticalDataAsCSV() {
  const data = await getAnalyticalData();
  let csvContent = "data:text/csv;charset=utf-8,";
  csvContent +=
    "SchoolName,SchoolRoll,Email,IsAdmin,SubscriptionStatus,SubscriptionCreationData,SubscriptionPeriodStart,SubscriptionPeriodEnd,PayByInvoice\r\n";

  data.forEach((row) => {
    if (row !== null) {
      csvContent +=
        (row.SchoolName ? row.SchoolName.replaceAll(",", " ") : "") +
        "," +
        (row.SchoolRoll ? row.SchoolRoll.replaceAll(",", " ") : "") +
        "," +
        row.Email +
        "," +
        row.IsAdmin +
        "," +
        (row.SubscriptionType ? row.SubscriptionType : "none") +
        "," +
        (row.PayByInvoice
          ? new Date(row.InvoiceDate).toLocaleDateString()
          : row.SubStart
          ? toDateTime(row.SubStart._seconds)
          : "") +
        "," +
        (row.CurrentSubStart ? toDateTime(row.CurrentSubStart._seconds) : "") +
        "," +
        (row.CurrentSubEnd ? toDateTime(row.CurrentSubEnd._seconds) : "") +
        "," +
        (row.PayByInvoice ? row.PayByInvoice : false) +
        "\r\n";
    }
  });
  var encodedUri = encodeURI(csvContent);

  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "ippn_account_emails.csv");
  document.body.appendChild(link); // Required for FF

  link.click();
}

function toDateTime(secs) {
  var t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t.toLocaleDateString();
}
