import { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import CategoryBox from "../../../components/CategoryBox";
import SearchBar from "../../../components/SearchBar";
import WorksheetBox from "../../../components/WorksheetBox";
import {
  deleteCategory,
  deleteStrand,
  deleteSubCategory,
  getAllCategoriesNew,
  getAllLessonsNew,
  getAllStrandsNew,
  getAllSubcategoryLessons,
  getCategoryNew,
  getImageUrl,
  getStrandCategories,
  getStrandNew,
  getSubcategories,
  getSubcategoryLessons,
  getSubCategoryNew,
  updateCategory,
  updateStrand,
  updateSubCategory,
  uploadFileToStorage,
  uploadLessonNew,
  uploadSubCategory,
} from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";

function AddLessonPage() {
  const { data } = useContext(UserContext);
  const navigate = useNavigate();

  const [lesson, setLesson] = useState({
    Title: "",
    Description: "",
    Equipment: "",
    Guid: "",
    Url: "",
    UrlEmbeded: "",
    Resources: "",
    VimeoEmbedLink: ""
  });
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [resources, setResources] = useState([]);
  const [resourceFiles, setResourceFiles] = useState([]);
  const [year0, setYear0] = useState(true);
  const [year1, setYear1] = useState(true);
  const [year2, setYear2] = useState(true);
  const [year3, setYear3] = useState(true);

  useEffect(() => {
    document.title = "Daly Exercise+ | Add Lesson";
    if ("UserName" in data) {
      setLoading(false);
    }
  }, [data]);

  const updateTitle = (val) => {
    setLesson({
      ...lesson,
      Title: val,
    });
  };

  const updateDescription = (val) => {
    setLesson({
      ...lesson,
      Description: val,
    });
  };

  const updateEquipment = (val) => {
    setLesson({
      ...lesson,
      Equipment: val,
    });
  };

  const updateUrl = (val) => {
    setLesson({
      ...lesson,
      Url: val,
    });
  };

  const updateUrlEmbeded = (val) => {
    setLesson({
      ...lesson,
      VimeoEmbedLink: val,
    });
  };

  const updateYear = (val) => {
    setLesson({
      ...lesson,
      Year: parseInt(val),
    });
  };

  const upload = async (e) => {
    e.preventDefault();
    let combined = {
      ...lesson,
      Resources: resources.join("&"),
    };
    let successfulUpload = true;
    if (year0) {
      let totalLesson = {
        ...combined,
        Year: 0,
      };
      successfulUpload = await uploadLessonNew(totalLesson);
    }
    if (year1) {
      let totalLesson = {
        ...combined,
        Year: 1,
      };
      successfulUpload = await uploadLessonNew(totalLesson);
    }
    if (year2) {
      let totalLesson = {
        ...combined,
        Year: 2,
      };
      successfulUpload = await uploadLessonNew(totalLesson);
    }
    if (year3) {
      let totalLesson = {
        ...combined,
        Year: 3,
      };
      successfulUpload = await uploadLessonNew(totalLesson);
    }

    let uploadPromises = [];

    if (successfulUpload) {
      resourceFiles.forEach((file) => {
        uploadPromises.push(uploadFileToStorage("resources", file));
      });
      await Promise.all(uploadPromises);
      navigate(-2);
    }
  };

  const resourceUpload = (e) => {
    if (e.target.files) {
      var file = e.target.files[0];
      var resource = file.name;
      setResources([...resources, resource]);
      setResourceFiles([...resourceFiles, file]);
    }
  };

  const removeResource = (resource) => {
    setResourceFiles(
      resourceFiles.filter((file) => file.name !== resource.title)
    );
    setResources(resources.filter((x) => x !== resource));
  };

  const loadResource = async (name) => {
    let url = await getImageUrl("resources/" + name);
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = name;
    document.body.appendChild(link);
    link.click();
  };
  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <br />
          <h1 className="pageTitle">Add Lesson</h1>
          <br />
          {loading ? (
            <div className="custom-loader"></div>
          ) : (
            <Form
              className="content-item-container"
              onSubmit={(e) => upload(e)}
            >
              <Row>
                <Col md={6}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Title</h3>
                    <Form.Control
                      value={lesson.Title}
                      required
                      onChange={(event) => updateTitle(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">ID</h3>
                    <Form.Control value="Generated" disabled={true} />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Description</h3>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={lesson.Description}
                      required
                      onChange={(event) =>
                        updateDescription(event.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Years</h3>
                    <Form.Check
                      id="year-0"
                      label="Junior and Senior"
                      checked={year0}
                      onChange={() => setYear0(!year0)}
                    />
                    <Form.Check
                      id="year-1"
                      label="1st and 2nd"
                      checked={year1}
                      onChange={() => setYear1(!year1)}
                    />
                    <Form.Check
                      id="year-2"
                      label="3rd and 4th"
                      checked={year2}
                      onChange={() => setYear2(!year2)}
                    />
                    <Form.Check
                      id="year-3"
                      label="5th and 6th"
                      checked={year3}
                      onChange={() => setYear3(!year3)}
                    />
                  </Form.Group>
                  {/* <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Year</h3>
                    <Form.Control
                      type="number"
                      value={lesson.Year}
                      required
                      onChange={(event) => updateYear(event.target.value)}
                    />
                  </Form.Group> */}
                </Col>
                <Col md={6}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Equipment</h3>
                    <Form.Control
                      value={lesson.Equipment}
                      required
                      onChange={(event) => updateEquipment(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                {/* <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Url</h3>
                    <Form.Control
                      value={lesson.Url}
                      required
                      onChange={(event) => updateUrl(event.target.value)}
                    />
                  </Form.Group>
                </Col> */}
                <Col md={6}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Vimeo Embed Link</h3>
                    <Form.Control
                      required
                      value={lesson.VidemoEmbedLink}
                      onChange={(event) => updateUrlEmbeded(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Resource Upload</h3>
                    <Form.Control
                      type="file"
                      placeholder="Add Resource"
                      onChange={(e) => resourceUpload(e)}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  {resources.length > 0 ? (
                    <div className="content-item-box">
                      <h3 className="content-item-title">Resources</h3>
                      {resources.map((resource, i) => {
                        return (
                          <div key={i}>
                            <WorksheetBox
                              worksheet={{ title: resource }}
                              delete={true}
                              deleteFunction={() => removeResource(resource)}
                            />
                            <br />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </Col>
                <Col md={4}>
                  <button type="submit" className={`custom-card-button`}>
                    Upload
                  </button>
                </Col>
              </Row>
              <hr />
              <br />
              <br />
              <div className="structured-lesson-video-box">
                <div className="structured-lesson-video">
                  <div className="video-responsive-structured">
                    {"UrlEmbeded" in lesson ? (
                      <iframe
                        src={lesson.VimeoEmbedLink}
                        allowFullScreen
                        title="Embedded youtube"
                      />
                    ) : (
                      <div width="853" height="480"></div>
                    )}
                  </div>
                </div>
              </div>
              <div className="structured-content-text-box">
                <h2 className="structured-content-heading">{lesson.Title}</h2>
                <br />
                <div className="structured-content-text">
                  {lesson.Description}
                </div>
                <br />
                <h2 className="structured-content-heading">
                  What will I need?
                </h2>
                <br />
                <ul>
                  {lesson.Equipment
                    ? lesson.Equipment.split(" & ").map((value, i) => {
                        return (
                          <li key={i} className="structured-content-text">
                            {value}
                          </li>
                        );
                      })
                    : ""}
                </ul>
                <br />
                {resources.length > 0 ? (
                  <div>
                    <h2 className="structured-content-heading">Resources</h2>
                    <br />
                    <ul>
                      {resources.map((value, i) => {
                        return (
                          <li
                            className="grow headingColour"
                            key={i}
                            onClick={() => loadResource(value)}
                          >
                            <a role="button">{value}</a>
                          </li>
                        );
                      })}
                    </ul>
                    <br />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Form>
          )}
        </Container>
      </div>
    </Container>
  );
}

export default AddLessonPage;
