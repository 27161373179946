import { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import CategoryBox from "../../../components/CategoryBox";
import SearchBar from "../../../components/SearchBar";
import WorksheetBox from "../../../components/WorksheetBox";
import {
  deleteCategory,
  deleteLessonNew,
  deleteStrand,
  deleteSubCategory,
  getAllCategoriesNew,
  getAllLessonsNew,
  getAllStrandsNew,
  getAllSubcategoryLessons,
  getCategoryNew,
  getImageUrl,
  getLessonNew,
  getStrandCategories,
  getStrandNew,
  getSubcategories,
  getSubcategoryLessons,
  getSubCategoryNew,
  updateCategory,
  updateLesson,
  updateStrand,
  updateSubCategory,
  uploadFileToStorage,
} from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";

function LessonDetailsPage() {
  const { data } = useContext(UserContext);
  const navigate = useNavigate();
  let { id } = useParams();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [lesson, setLesson] = useState([]);
  const [lessonCopy, setLessonCopy] = useState([]);
  const [resources, setResources] = useState([]);
  const [resourceFiles, setResourceFiles] = useState([]);

  useEffect(() => {
    document.title = "Daly Exercise+ | Lesson Details";
    if ("UserName" in data) {
      getLessonNew(id).then((res) => {
        setLesson(res);
        setLessonCopy(res);
        setLoading(false);
        if ("Resources" in res) {
          if (res.Resources !== "") {
            setResources(res.Resources.split("&"));
          }
        }
      });
    }
  }, [data, id]);

  const changeEdit = async (e) => {
    e.preventDefault();
    if (edit) {
      let combined = {
        ...lessonCopy,
        Resources: resources.join("&"),
      };
      let updateSuccessful = await updateLesson(combined);
      if (updateSuccessful) {
        resourceFiles.forEach((file) => {
          uploadFileToStorage("resources", file);
        });
        setLesson({
          ...combined,
        });
      } else {
        return;
      }
    }
    setEdit(!edit);
  };

  const cancelEdit = (e) => {
    e.preventDefault();
    setLessonCopy({ ...lesson });
    if ("Resources" in lesson) {
      if (lesson.Resources !== "") {
        setResources(lesson.Resources.split("&"));
      } else {
        setResources([]);
        setResourceFiles([]);
      }
    } else {
      setResources([]);
      setResourceFiles([]);
    }
    setEdit(false);
  };

  const updateTitle = (val) => {
    setLessonCopy({
      ...lessonCopy,
      Title: val,
    });
  };

  const updateDescription = (val) => {
    setLessonCopy({
      ...lessonCopy,
      Description: val,
    });
  };

  const updateEquipment = (val) => {
    setLessonCopy({
      ...lessonCopy,
      Equipment: val,
    });
  };

  const updateUrl = (val) => {
    setLessonCopy({
      ...lessonCopy,
      Url: val,
    });
  };

  const updateUrlEmbeded = (val) => {
    setLessonCopy({
      ...lessonCopy,
      VimeoEmbedLink: val,
    });
  };

  const updateYear = (val) => {
    setLessonCopy({
      ...lessonCopy,
      Year: parseInt(val),
    });
  };

  const deleteLessonClicked = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you wish to delete this Lesson?")) {
      let success = await deleteLessonNew(lesson);
      if (success) {
        navigate("/content-management");
      }
    }
  };

  const resourceUpload = (e) => {
    if (e.target.files) {
      var file = e.target.files[0];
      var resource = file.name;
      setResources([...resources, resource]);
      setResourceFiles([...resourceFiles, file]);
    }
  };

  const removeResource = (resource) => {
    setResourceFiles(
      resourceFiles.filter((file) => file.name !== resource.title)
    );
    setResources(resources.filter((x) => x !== resource));
  };

  const loadResource = async (name) => {
    let url = await getImageUrl("resources/" + name);
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = name;
    document.body.appendChild(link);
    link.click();
  };
  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <br />
          <h1 className="pageTitle">Lesson Details</h1>
          <br />
          {loading ? (
            <div className="custom-loader"></div>
          ) : (
            <Form className="content-item-container">
              <Row>
                <Col md={6}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Title</h3>
                    <Form.Control
                      value={lessonCopy.Title}
                      disabled={!edit}
                      onChange={(event) => updateTitle(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">ID</h3>
                    <Form.Control value={lessonCopy.Guid} disabled={true} />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Description</h3>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={lessonCopy.Description}
                      disabled={!edit}
                      onChange={(event) =>
                        updateDescription(event.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Year</h3>
                    <Form.Control
                    type="number"
                      value={lessonCopy.Year}
                      disabled={!edit}
                      onChange={(event) => updateYear(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Equipment</h3>
                    <Form.Control
                      value={lessonCopy.Equipment}
                      disabled={!edit}
                      onChange={(event) => updateEquipment(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                {/* <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Url</h3>
                    <Form.Control
                      value={lessonCopy.Url}
                      disabled={!edit}
                      onChange={(event) => updateUrl(event.target.value)}
                    />
                  </Form.Group>
                </Col> */}
                <Col md={6}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Vimeo Embed Url</h3>
                    <Form.Control
                      value={lessonCopy.VimeoEmbedLink}
                      disabled={!edit}
                      onChange={(event) => updateUrlEmbeded(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Resource Upload</h3>
                    <Form.Control
                      type="file"
                      disabled={!edit}
                      placeholder="Add Resource"
                      onChange={(e) => resourceUpload(e)}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  {resources.length > 0 ? (
                    <div className="content-item-box">
                      <h3 className="content-item-title">Resources</h3>
                      {resources.map((resource, i) => {
                        return (
                          <div key={i}>
                            <WorksheetBox
                              worksheet={{ title: resource }}
                              delete={edit}
                              deleteFunction={() => removeResource(resource)}
                            />
                            <br />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </Col>
                <Col md={4}>
                  <button
                    type="button"
                    className={`custom-card-button`}
                    onClick={(e) => changeEdit(e)}
                  >
                    {edit ? "Save" : "Edit"}
                  </button>
                </Col>
                <Col md={4}>
                  <button
                    disabled={!edit}
                    type="button"
                    className={`custom-card-button`}
                    onClick={(e) => cancelEdit(e)}
                  >
                    Cancel
                  </button>
                </Col>
                <Col md={4}>
                  <button
                    type="button"
                    className={`custom-card-button custom-card-button-variant-delete`}
                    onClick={(e) => deleteLessonClicked(e)}
                  >
                    Delete
                  </button>
                </Col>
              </Row>
              <hr />
              <br />
              <br />
              <div className="structured-lesson-video-box">
                <div className="structured-lesson-video">
                  <div className="video-responsive-structured">
                    {"UrlEmbeded" in lessonCopy ? (
                      <iframe
                        src={lessonCopy.VimeoEmbedLink}
                        allowFullScreen
                        title="Embedded youtube"
                      />
                    ) : (
                      <div width="853" height="480"></div>
                    )}
                  </div>
                </div>
              </div>
              <div className="structured-content-text-box">
                <h2 className="structured-content-heading">
                  {lessonCopy.Title}
                </h2>
                <br />
                <div className="structured-content-text">
                  {lessonCopy.Description}
                </div>
                <br />
                <h2 className="structured-content-heading">
                  What will I need?
                </h2>
                <br />
                <ul>
                  {lessonCopy.Equipment
                    ? lessonCopy.Equipment.split(" & ").map((value, i) => {
                        return (
                          <li key={i} className="structured-content-text">
                            {value}
                          </li>
                        );
                      })
                    : ""}
                </ul>
                <br />
                {resources.length > 0 ? (
                  <div>
                    <h2 className="structured-content-heading">Resources</h2>
                    <br />
                    <ul>
                      {resources.map((value, i) => {
                        return (
                          <li
                            className="grow headingColour"
                            key={i}
                            onClick={() => loadResource(value)}
                          >
                            <a role="button">{value}</a>
                          </li>
                        );
                      })}
                    </ul>
                    <br />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Form>
          )}
        </Container>
      </div>
    </Container>
  );
}

export default LessonDetailsPage;
